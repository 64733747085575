import { Component } from '@angular/core';
import { ProductService } from '../../service/product.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrls: ['./product-variant.component.less']
})
export class ProductVariantComponent {

  variantId: string | null = null;
  name = '';

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
  ){}
  
  ngOnInit() {
    // Get both productId and variantId from the route parameters
    this.route.paramMap.subscribe(params => {
      this.variantId = params.get('id');
      
      if (this.variantId) {
        // Load variant data using these IDs
        this.loadVariantData();
      }
    });
  }

  private loadVariantData() {
    // Implement your data loading logic here
    console.log(`Loading variant ${this.variantId}`);
    this.productService.fetchProductVariant(this.variantId as string).subscribe((response) => {
      this.name = response?.data?.productVariant?.name ?? '';
    });
  }
}
