import { Injectable } from '@angular/core'
import { createStore, withProps } from '@ngneat/elf'
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state'
import { tap } from 'rxjs'
import {
  AuthenticationInput,
  AuthenticationRegisterInput,
  CurrentUser,
  CurrentUserChannel,
  LoginGQL,
  MeGQL,
  Permission,
  RegisterGQL,
} from '../gql/shop/generated'
import { AppService } from './app.service'

interface AuthState {
  user: CurrentUser | null
  currentUserChannel: CurrentUserChannel | null
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authStore$ = createStore(
    { name: 'auth' },
    withProps<AuthState>({
      user: null,
      currentUserChannel: null,
    })
  )

  constructor(
    private loginGQL: LoginGQL,
    private appService: AppService,
    private registerGQL: RegisterGQL,
    private meGQL: MeGQL
  ) {
    persistState(this.authStore$, {
      key: 'auth',
      storage: localStorageStrategy,
    })
  }

  login(input: AuthenticationInput) {
    return this.appService.withLoader(() => {
      return this.loginGQL
        .mutate({
          authenticationInput: input,
        })
        .pipe(
          tap((response) => {
            const user = response.data?.authenticate as CurrentUser
            const defaultChannel = user.channels.find((channel) =>
              channel.permissions.includes(Permission.SuperAdmin)
            )

            console.log({ defaultChannel })

            this.authStore$.update((state) => ({
              ...state,
              ...state,
              user,
              currentUserChannel: defaultChannel ?? null,
            }))
          })
        )
    })
  }

  logout() {
    this.authStore$.update((state) => ({
      ...state,
      user: null,
      currentUserChannel: null,
    }))
  }

  register(input: AuthenticationRegisterInput) {
    return this.appService.withLoader(() => {
      return this.registerGQL.mutate({
        authenticationRegisterInput: input,
      })
    })
  }

  me() {
    return this.appService.withLoader(() => {
      return this.meGQL.fetch().pipe(
        tap((response) => {
          console.log({ response })
        })
      )
    })
  }
}
