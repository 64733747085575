import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { tap } from 'rxjs/operators';
import {
  CreateFacetGQL,
  CreateFacetInput,
  CreateFacetValueInput,
  CreateFacetValuesGQL,
  Facet,
  FacetGQL,
  FacetList,
  FacetListOptions,
  FacetsGQL,
  FacetValueList,
  FacetValueListOptions,
  FacetValuesGQL,
  InputMaybe,
  LogicalOperator,
  SortOrder,
  UpdateFacetGQL,
  UpdateFacetInput,
  UpdateFacetValueInput,
  UpdateFacetValuesGQL,
} from '../gql/shop/generated';
import { AppService } from './app.service';
import { AuthService } from './auth.service';

interface FacetesState {
  facets: FacetList;
  params: FacetListOptions;
  currentPage: number;
  pageSize: number;
  facet: Facet | undefined;
  facetValues: FacetValueList;
}

const initialState = withProps<FacetesState>({
  facets: {
    items: [],
    totalItems: 0,
  },
  params: {
    filter: undefined,
    filterOperator: LogicalOperator.AND,
    skip: 0,
    take: 10,
    sort: undefined,
  },
  currentPage: 1,
  pageSize: 10,
  facet: undefined,
  facetValues: {
    items: [],
    totalItems: 0,
  },
});

@Injectable({ providedIn: 'root' })
export class FacetService {
  private store = createStore({ name: 'facet' }, initialState);

  facets$ = this.store.pipe(select((state) => state.facets));
  facet$ = this.store.pipe(select((state) => state.facet));
  currentPage$ = this.store.pipe(select((state) => state.currentPage));
  pageSize$ = this.store.pipe(select((state) => state.pageSize));
  facetValues$ = this.store.pipe(select((state) => state.facetValues));

  constructor(
    private facetsGQL: FacetsGQL,
    private appService: AppService,
    private createFacetGQL: CreateFacetGQL,
    private facetGQL: FacetGQL,
    private facetValuesGQL: FacetValuesGQL,
    private updateFacetGQL: UpdateFacetGQL,
    private updateFacetValuesGQL: UpdateFacetValuesGQL,
    private createFacetValuesGQL: CreateFacetValuesGQL,
    private authService: AuthService,
  ) {}

  fetchFacetValues(options?: InputMaybe<FacetValueListOptions>) {
    return this.appService.withLoader(() =>
      this.facetValuesGQL
        .fetch(
          {
            options,
          },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
          },
        )
        .pipe(
          tap({
            next: (response) => {
              this.store.update((state) => ({
                ...state,
                facetValues: response.data.facetValues as FacetValueList,
              }));
            },
          }),
        ),
    );
  }

  fetchFacets() {
    return this.appService.withLoader(() =>
      this.facetsGQL
        .fetch(
          { options: this.store.getValue().params },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
            fetchPolicy: 'network-only',
          },
        )
        .pipe(
          tap({
            next: (response) => {
              this.store.update((state) => ({
                ...state,
                facets: response.data.facets as FacetList,
              }));
            },
          }),
        ),
    );
  }

  updatePagination(page: number, pageSize: number) {
    this.store.update((state) => ({
      ...state,
      currentPage: page,
      pageSize: pageSize,
      params: {
        ...state.params,
        skip: (page - 1) * pageSize,
      },
    }));
  }

  updateSort(sortField: string, sortOder: string) {
    this.store.update((state) => ({
      ...state,
      params: {
        sort: {
          [sortField]: SortOrder.ASC,
        },
      },
    }));
  }

  goToPage(page: number) {
    this.store.update((state) => ({
      ...state,
      currentPage: page,
      params: {
        ...state.params,
        skip: (page - 1) * state.pageSize,
      },
    }));
    this.fetchFacets().subscribe;
  }

  nextPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(currentPage + 1);
  }

  previousPage() {
    const currentPage = this.store.getValue().currentPage;
    this.goToPage(Math.max(1, currentPage - 1));
  }

  createFacet(input: CreateFacetInput) {
    return this.appService.withLoader(() =>
      this.createFacetGQL.mutate(
        { input },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
        },
      ),
    );
  }

  createFacetValues(input: CreateFacetValueInput | CreateFacetValueInput[]) {
    return this.appService.withLoader(() =>
      this.createFacetValuesGQL.mutate(
        {
          createFacetValuesInput: input,
        },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
        },
      ),
    );
  }

  updateFacetValues(input: UpdateFacetValueInput | UpdateFacetValueInput[]) {
    return this.appService.withLoader(() =>
      this.updateFacetValuesGQL.mutate(
        {
          updateFacetValuesInput: input,
        },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
        },
      ),
    );
  }

  updateFacet(input: UpdateFacetInput) {
    return this.appService.withLoader(() =>
      this.updateFacetGQL.mutate(
        {
          updateFacetInput: input,
        },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
        },
      ),
    );
  }

  fetchFacet(id: string) {
    return this.appService.withLoader(() =>
      this.facetGQL
        .fetch(
          {
            facetId: id,
          },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
          },
        )
        .pipe(
          tap({
            next: (response) => {
              this.store.update((state) => ({
                ...state,
                facet: response.data.facet as Facet,
              }));
            },
          }),
        ),
    );
  }

  clearFacet() {
    this.store.update((state) => ({
      ...state,
      facet: undefined,
    }));
  }
}
