<form
  nz-form
  [nzLayout]="validateForm.controls.formLayout.value"
  [formGroup]="validateForm"
>
  <div class="page-container">
    <div nz-row class="page-container-head">
      <div nz-col nzSpan="12">
        <h2 *ngIf="isCreation">Create Product</h2>
        <h2 *ngIf="!isCreation">Edit Product</h2>
      </div>
      <div nz-col nzSpan="12" class="page-container-toolbar">
        <div class="flex-end">
          <button *ngIf="!isCreation" nz-button nzDanger nzType="primary" (click)="showRemoveProductModal = true">
            <span nz-icon nzType="ng-zorro:trash"></span>
            Remove
          </button>
          <button
            (click)="submitForm()"
            *ngIf="!isCreation"
            nz-button
            nzType="primary"
            (keydown.enter)="$event.preventDefault()"
            [disabled]="finishSubmit"
          >
            <span nz-icon nzType="ng-zorro:add"></span>
            Save
          </button>
          <button
            (click)="submitForm()"
            *ngIf="isCreation"
            nz-button
            nzType="primary"
            (keydown.enter)="$event.preventDefault()"
            [disabled]="finishSubmit"
          >
            <span nz-icon nzType="ng-zorro:add"></span>
            Create
          </button>
          <nz-modal [(nzVisible)]="showRemoveProductModal" nzTitle="Remove Product" (nzOnCancel)="showRemoveProductModal = false" (nzOnOk)="deleteProduct()">
            <div *nzModalContent>Please confirm you want to remove this product.</div>
          </nz-modal>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>General Info</h2>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Title</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="validateForm.controls.product.controls.name"
                    placeholder="Title"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Slug</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="validateForm.controls.product.controls.slug"
                    placeholder="Code"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Visibility</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <nz-switch
                    [formControl]="
                      validateForm.controls.product.controls.enabled
                    "
                  ></nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="24">
              <p>Description</p>
              <quill-editor
                [formControl]="
                  validateForm.controls.product.controls.description
                "
                name="description"
                class="quill-editor"
                [minLength]="3"
              ></quill-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Media</h2>
        </div>
        <div nz-col nzSpan="24">
          <div
            class="drop-zone"
            (drop)="onDropZoneDrop($event)"
            (dragover)="onDropZoneDragOver($event)"
            (dragleave)="onDropZoneDragLeave($event)"
            [ngClass]="{ dragover: isDropZoneDragging }"
          >
            <input class="drop-zone-input" type="file" (change)="onFileSelected($event)"
             accept=".svg,.png,.jpg,.jpeg,.gif" #fileInput/>
            <div class="drop-zone-inner">
              <span nz-icon nzType="ng-zorro:upload"></span>
              <span> <b>Click to upload</b> or drag and drop </span>
              <p>SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
              <p *ngIf="mediaUploadingMessage" style="color: red;">{{ mediaUploadingMessage }}</p>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <!-- Loop images -->
          <div class="product-images">
            <div
              *ngFor="
                let item of validateForm.controls.product.controls.assets.controls;
                let index = index
              "
              class="product-images-item"
            >
              <div
                (click)="
                  this.validateForm.controls.product.controls.assets.removeAt(
                    index
                  )
                "
                class="close"
              >
                <span nz-icon nzType="close-circle" nzTheme="fill"></span>
              </div>
              <div class="image-info">
                <img
                  nz-image
                  width="200px"
                  [nzSrc]="displayImage(item)"
                  [alt]="item.getRawValue().name"
                />
                <p>{{ item.getRawValue().name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Facets</h2>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Facets</nz-form-label>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <div class="selectbox-group">
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzPlaceHolder="input search text"
                  (nzOnSearch)="searchFacet($event)"
                  [formControl]="validateForm.controls.selectedFacetValue"
                >
                  <ng-container
                    *ngIf="facetService.facetValues$ | async as facetValues"
                  >
                    <nz-option
                      *ngFor="let item of facetValues?.items"
                      [nzLabel]="item.name + ': ' + item.code"
                      [nzValue]="item"
                    >
                    </nz-option>
                  </ng-container>
                </nz-select>
                <button nz-button nzType="primary">
                  <span nz-icon nzType="ng-zorro:add"></span>
                  Add Facet
                </button>
              </div>
              <div class="selectbox-items">
                <nz-tag
                  *ngFor="
                    let facetValue of validateForm.controls.product.controls
                      .facetValues.controls;
                    let index = index
                  "
                  nzMode="closeable"
                  ngClass="alpha"
                  class="facet-tag"
                  (nzOnClose)="
                    validateForm.controls.product.controls.facetValues.removeAt(
                      index
                    )
                  "
                  ><b>{{ facetValue.getRawValue().name }}:</b>&nbsp;{{ facetValue.getRawValue().code }}</nz-tag
                >
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Pricing</h2>
        </div>
        <div nz-col nzSpan="24">
          <label nz-checkbox [nzChecked]="hasVariants"
          (nzCheckedChange)="onHasVariantsChange($event)"
            >Product has variants</label
          >
        </div>
        <div *ngIf="!hasVariants" nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">SKU</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input nz-input placeholder="SKU" [formControl]="validateForm.controls.product.controls.variant.controls.sku" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Price</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input 
                    nz-input
                    type="number" 
                    min="0" 
                    step="0.01" 
                    placeholder="Price" 
                    [formControl]="validateForm.controls.product.controls.variant.controls.price"
                    (input)="onPriceInput($event, validateForm.controls.product.controls.variant.controls.price)"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Stock</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input nz-input type="number" min="0" step="1" placeholder="Stock" [formControl]="validateForm.controls.product.controls.variant.controls.stock" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>

        <div *ngIf="hasVariants" nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="24">
              <h3>Variant Option Groups</h3>
            </div>

            <div nz-col nzSpan="24">
              <div
                *ngFor="
                  let optionGroup of validateForm.controls.product.controls
                    .optionGroups.controls
                "
                nz-row
              >
                <div nz-col nzXs="24" nzSm="8" nzSpan="8">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">Option</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <input
                        nz-input
                        [formControl]="optionGroup.controls.name"
                        placeholder="Option"
                        (keydown.enter)="
                          $event.preventDefault();
                          $event.stopPropagation();"
                      />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzXs="24" nzSm="8" nzSpan="8">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">Option Values</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                      <input
                        (keydown.enter)="
                          $event.preventDefault();
                          $event.stopPropagation();
                          addVariantOptionGroupValue(
                            optionGroup.controls.newItem,
                            optionGroup.controls.options
                          )
                        "
                        (keydown.tab)="
                          addVariantOptionGroupValue(
                            optionGroup.controls.newItem,
                            optionGroup.controls.options,
                            $event
                          )
                        "
                        [formControl]="optionGroup.controls.newItem"
                        nz-input
                        placeholder="New value"
                      />
                    </nz-form-control>
                  </nz-form-item>
                  <div class="selectbox-items">
                    <nz-tag
                      *ngFor="
                        let option of optionGroup.controls.options.controls
                      "
                      nzMode="closeable"
                      ngClass="alpha"
                      (nzOnClose)="closeOption($event, optionGroup, option)"
                      >{{ option.getRawValue().name }}</nz-tag
                    >
                  </div>
                  <nz-form-item>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <button
                        (click)="
                          addVariantOptionGroupValue(
                            optionGroup.controls.newItem,
                            optionGroup.controls.options
                          )
                        "
                        nz-button
                        nzType="primary"
                      >
                        <span nz-icon nzType="ng-zorro:add"></span>
                        Add Option Value
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzXs="24" nzSm="8" nzSpan="8">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24"></nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <button nz-button nzDanger nzType="primary" (click)="
                      removeVariantOptionGroupValue(
                        optionGroup
                      )
                    ">
                        <span nz-icon nzType="ng-zorro:trash"></span>
                        Remove Option Group
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
            <div nz-col nzSpan="24">
              <button
                (click)="addVariantOptionGroup()"
                nz-button
                nzType="primary"
              >
                <span nz-icon nzType="ng-zorro:add"></span>
                Add Option Group
              </button>
            </div>

            <div nz-col nzSpan="24">
              <h3>Product Variants</h3>
            </div>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label [nzSpan]="24"
                  >Assign To Stock Location</nz-form-label
                >
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <nz-select
                    [formControl]="validateForm.controls.selectedStockLocation"
                    (ngModelChange)="createStockLocation()"
                  >
                    <nz-option
                      *ngFor="
                        let item of validateForm.controls.stockLocations
                          .controls
                      "
                      [nzValue]="item.controls"
                      [nzLabel]="item.getRawValue().name"
                    >
                      {{ item.getRawValue().name }}
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              *ngFor="
                let stockLocation of validateForm.controls.product.controls
                  .stockLocations.controls
              "
              nz-col
              nzSpan="24"
            >
              <h4>
                {{ stockLocation.getRawValue().name }}
                <span
                  nz-icon
                  nzType="info-circle"
                  nzTheme="outline"
                  nz-tooltip
                  nzTooltipTitle="In this section, you have the option to create different product
                variants that are specific to each of your stock locations. If
                you manage multiple stock locations, you can assign different
                variants of the same product to each location. However, if you
                only have one stock location, the system will automatically use
                this location as the default for all your product variants."
                ></span>
              </h4>
              <nz-table
                #rowSelectionTable
                #sortTable
                nzShowSizeChanger
                #basicTable
                [nzLoading]="false"
                [nzFrontPagination]="false"
                [nzShowPagination]="false"
                [nzData]="stockLocation.controls.variants.getRawValue()"
                class="product-table"
              >
                <thead>
                  <tr>
                    <th [nzChecked]="false" [nzIndeterminate]="false"></th>
                    <th>MEDIA</th>
                    <th>VARIANT OPTIONS</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>STOCK</th>
                    <th>SKU</th>
                    <th>ENABLED</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let variant of stockLocation.controls.variants.controls
                    "
                  >
                    <td [nzChecked]="false"></td>
                    <td></td>
                    <td>
                      <nz-tag
                        *ngFor="let option of variant.controls.options.controls"
                        ngClass="alpha"
                      >
                        {{ option.getRawValue().name }}
                      </nz-tag>
                    </td>
                    <td>
                      <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                          <input
                            nz-input
                            [formControl]="variant.controls.name"
                            placeholder="Red Iphone"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </td>
                    <td>
                      <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                          <nz-input-number
                            [nzFormatter]="currencyFormat"
                            [formControl]="variant.controls.price"
                            placeholder="$0.00"
                            min="0" 
                            step="0.01" 
                            (input)="onPriceInput($event, variant.controls.price)"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </td>
                    <td>
                      <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                          <input
                            nz-input
                            type="number"
                            placeholder="5"
                            [formControl]="variant.controls.stock"
                            min="0"
                            step="1"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </td>
                    <td>
                      <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                          <input
                            nz-input
                            [formControl]="variant.controls.sku"
                            placeholder="0"
                          />
                        </nz-form-control>
                      </nz-form-item>
                    </td>
                    <td>
                      <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                          <nz-switch
                            [formControl]="variant.controls.enabled"
                          ></nz-switch>
                        </nz-form-control>
                      </nz-form-item>
                    </td>
                    <td>
                      <button
                        (click)="removeVariant(variant, stockLocation)"
                        nz-button
                        nzType="primary"
                        ngClass="epsilon"
                        nzDanger=""
                      >
                        <span nz-icon nzType="ng-zorro:trash"></span>
                        Remove Variant
                      </button>
                      <button
                        (click)="editVariantCreation(variant, stockLocation)"
                        nz-button
                        nzType="primary"
                        ngClass="epsilon"
                      >
                        <span nz-icon nzType="ng-zorro:add"></span>
                        Quick Edit
                      </button>

                      <button
                        (click)="goToProductVariant(variant.controls.id.value)"
                        nz-button
                        nzType="primary"
                        ngClass="epsilon"
                      >
                        <span nz-icon nzType="ng-zorro:add"></span>
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
              <button
                (click)="showVariantCreation(stockLocation)"
                nz-button
                nzType="primary"
                ngClass="epsilon"
              >
                <span nz-icon nzType="ng-zorro:add"></span>
                Add Variant
              </button>
              <button
                (click)="removeStockLocation(stockLocation)"
                nz-button
                nzType="primary"
                ngClass="epsilon"
                nzDanger
              >
                <span nz-icon nzType="ng-zorro:trash"></span>
                Remove Stock Location
              </button>
              <nz-modal
                [nzFooter]="createVariationFooter"
                [(nzVisible)]="showVariantModal"
                nzTitle="Variant Creation"
                (nzOnCancel)="cancelVariantCreation()"
                (nzOnOk)="completeVariantCreation()"
              >
                <div *nzModalContent>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">Name</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <input
                        nz-input
                        [formControl]="
                          validateForm.controls.variantModal.controls.variant
                            .controls.name
                        "
                        placeholder="Name"
                      />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">Price</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <input
                        nz-input
                        type="number"
                        [formControl]="
                          validateForm.controls.variantModal.controls.variant
                            .controls.price
                        "
                        placeholder="Price"
                        min="0" 
                        step="0.01" 
                        (input)="onPriceInput($event, validateForm.controls.variantModal.controls.variant.controls.price)"
                      />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">SKU</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <input
                        nz-input
                        [formControl]="
                          validateForm.controls.variantModal.controls.variant
                            .controls.sku
                        "
                        placeholder="SKU"
                      />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="24">Stock</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <input
                        nz-input
                        type="number"
                        [formControl]="
                          validateForm.controls.variantModal.controls.variant
                            .controls.stock
                        "
                        placeholder="Stock"
                        min="0"
                        step="1"
                      />
                    </nz-form-control>
                  </nz-form-item>
                  <h3>Variant Options</h3>
                  <nz-form-item
                    *ngFor="
                      let optionGroup of validateForm.controls.product.controls
                        .optionGroups.controls
                    "
                  >
                    <nz-form-label [nzSpan]="24">{{
                      optionGroup.getRawValue().name
                    }}</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                      <nz-select
                        [formControl]="optionGroup.controls.selectedItem"
                        [compareWith]="compareId"
                      >
                        <nz-option [nzValue]="null"></nz-option>
                        <nz-option
                          *ngFor="
                            let option of optionGroup.controls.options.controls
                          "
                          [nzValue]="option.controls"
                          [nzLabel]="option.getRawValue().name"
                        >
                          {{ option.getRawValue().name }}
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <ng-template #createVariationFooter>
                  <button
                    nz-button
                    nzType="default"
                    (click)="cancelVariantCreation()"
                  >
                    Cancel
                  </button>
                  <button
                    nz-button
                    nzType="primary"
                    (click)="completeVariantCreation()"
                  >
                    Create
                  </button>
                </ng-template>
              </nz-modal>
            </div>
            <div nz-col nzSpan="24"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2 class="primary">Advance Options</h2>
        </div>
        <div nz-col nzSpan="24">
          <h3>Channel Options</h3>
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label [nzSpan]="24"
                  >Assign This Product To Other Channels</nz-form-label
                >
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <nz-select>
                    <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                    <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                    <nz-option
                      nzValue="disabled"
                      nzLabel="Disabled"
                      nzDisabled
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <nz-table
                #rowSelectionTable
                #sortTable
                nzShowSizeChanger
                [nzData]="[]"
                [nzFrontPagination]="false"
                [nzLoading]="false"
                [nzTotal]="0"
                class="product-table"
              >
                <thead>
                  <tr>
                    <th [nzChecked]="false" [nzIndeterminate]="false"></th>
                    <th>CHANNEL NAME</th>
                    <th>PERMISSIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of [1]">
                    <td [nzChecked]="false"></td>
                    <td class="product-table-title">Name</td>
                    <td>
                      <nz-tag ngClass="alpha">Only View</nz-tag>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
