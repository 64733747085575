<form>
  <div class="page-container">
    <div nz-row class="page-container-head">
        <div nz-col nzSpan="12">
            <h2>Product Variant</h2>
        </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>General Info</h2>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row>
            {{name}}
          </div>  
        </div>
      </div>
    </div>
  </div>
</form>
