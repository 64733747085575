<div class="register">
  <div nz-row [nzGutter]="24">
    <div
      *ngIf="formSubmitted"
      nz-col
      [nzSpan]="24"
      [nzLg]="12"
      class="form-container-col"
    >
      <div class="form-submitted-container">
        <span nz-icon>
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="check-circle" clip-path="url(#clip0_997_2481)">
              <path
                id="Vector"
                d="M40 0C32.0888 0 24.3552 2.34596 17.7772 6.74121C11.1992 11.1365 6.07234 17.3836 3.04484 24.6927C0.0173314 32.0017 -0.774802 40.0444 0.768607 47.8036C2.31202 55.5628 6.12165 62.6902 11.7157 68.2842C17.3098 73.8783 24.4372 77.688 32.1964 79.2314C39.9556 80.7748 47.9983 79.9827 55.3073 76.9552C62.6164 73.9277 68.8635 68.8008 73.2588 62.2228C77.654 55.6448 80 47.9112 80 40C79.9884 29.3949 75.7703 19.2275 68.2714 11.7286C60.7725 4.22966 50.6051 0.0116464 40 0ZM54.828 34.828L38.828 50.828C38.0779 51.5779 37.0607 51.9991 36 51.9991C34.9394 51.9991 33.9221 51.5779 33.172 50.828L25.172 42.828C24.4434 42.0736 24.0402 41.0632 24.0493 40.0144C24.0584 38.9656 24.4791 37.9623 25.2207 37.2207C25.9624 36.4791 26.9656 36.0584 28.0144 36.0493C29.0632 36.0402 30.0736 36.4434 30.828 37.172L36 42.344L49.172 29.172C49.9264 28.4434 50.9368 28.0402 51.9856 28.0493C53.0344 28.0584 54.0377 28.4791 54.7793 29.2207C55.5209 29.9623 55.9416 30.9656 55.9507 32.0144C55.9598 33.0632 55.5566 34.0736 54.828 34.828Z"
                fill="#0E7BD6"
              />
            </g>
            <defs>
              <clipPath id="clip0_997_2481">
                <rect width="80" height="80" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <h3>Account Created</h3>
        <p>
          We have sent you an activation link to your email. Please check your
          email and click “Activate” button to proceed.
        </p>
        <button class="submit" nz-button nzType="primary">Awesome!</button>
      </div>
    </div>
    <div
      *ngIf="!formSubmitted"
      nz-col
      [nzSpan]="24"
      [nzLg]="12"
      class="form-container-col"
    >
      <div class="form-container">
        <form
          nz-form
          [nzLayout]="validateForm.controls.formLayout.value"
          [formGroup]="validateForm"
          (ngSubmit)="submitForm()"
        >
          <div nz-row>
            <div nz-col [nzSpan]="24">
              <h1>Create your account.</h1>
              <p>Already have an account? <a href="/login">Login here</a></p>
            </div>
          </div>
          <div>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Email</nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    [nzErrorTip]="emailErrorTemplate"
                  >
                    <input
                      nz-input
                      formControlName="email"
                      placeholder="bonnie@shopzo.com"
                    />
                  </nz-form-control>
                  <ng-template #emailErrorTemplate>
                    <!-- Different error messages based on the validation errors -->
                    <ng-container
                      *ngIf="validateForm.controls.email.errors?.['required']"
                      >Please enter your email address.</ng-container
                    >
                    <ng-container
                      *ngIf="validateForm.controls.email.errors?.['email']"
                      >Please enter a valid email address.</ng-container
                    >
                  </ng-template>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Shop Name</nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    nzErrorTip="Please fill your shop name"
                  >
                    <input
                      nz-input
                      formControlName="shopName"
                      placeholder="Oregon Small Shop"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Name</nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    nzErrorTip="Please fill your name"
                  >
                    <input
                      nz-input
                      formControlName="name"
                      placeholder="Bonnie"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Surname</nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    nzErrorTip="Please fill your surname"
                  >
                    <input
                      nz-input
                      formControlName="surname"
                      placeholder="Green"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">
                    Country
                    <span
                      nz-icon
                      nz-popover
                      [nzPopoverContent]="contentTemplate"
                    >
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.4778 2.63594L6.20002 3.05168L6.4778 2.63594C6.24757 2.48211 5.9769 2.4 5.7 2.4C5.3287 2.4 4.9726 2.5475 4.71005 2.81005C4.4475 3.0726 4.3 3.4287 4.3 3.8C4.3 4.07689 4.38211 4.34757 4.53594 4.5778C4.59053 4.6595 4.65329 4.7348 4.72297 4.8027C4.45938 4.82119 4.21024 4.93412 4.02219 5.12218C3.81589 5.32847 3.7 5.60826 3.7 5.9C3.7 6.19174 3.8159 6.47153 4.02218 6.67782C4.22847 6.88411 4.50826 7 4.8 7H4.9V7.8H4.8C4.50826 7.8 4.22847 7.91589 4.02218 8.12218C3.8159 8.32847 3.7 8.60826 3.7 8.9C3.7 9.19174 3.81589 9.47153 4.02218 9.67782C4.22848 9.88411 4.50826 10 4.8 10H7.2C7.49174 10 7.77153 9.88411 7.97782 9.67782C8.18411 9.47153 8.3 9.19174 8.3 8.9C8.3 8.60826 8.18411 8.32847 7.97782 8.12218C7.77153 7.91589 7.49174 7.8 7.2 7.8H7.1V5.9C7.1 5.60826 6.98411 5.32847 6.77782 5.12218C6.70189 5.04625 6.61599 4.98256 6.52321 4.9324C6.58218 4.88953 6.63796 4.84194 6.68995 4.78995C6.88575 4.59415 7.01908 4.3447 7.0731 4.07313L6.58271 3.97558L7.0731 4.07313C7.12712 3.80155 7.0994 3.52006 6.99343 3.26424L6.53149 3.45558L6.99343 3.26424C6.88747 3.00843 6.70803 2.78978 6.4778 2.63594ZM2.94437 1.92692C3.84876 1.32262 4.91203 1.00005 5.99973 1C7.45797 1.00167 8.85602 1.5817 9.88716 2.61284C10.9184 3.64405 11.4984 5.0422 11.5 6.50055C11.4999 7.58815 11.1773 8.65132 10.5731 9.55563C9.96874 10.4601 9.10975 11.1651 8.10476 11.5813C7.09977 11.9976 5.9939 12.1065 4.92701 11.8943C3.86011 11.6821 2.8801 11.1583 2.11092 10.3891C1.34173 9.6199 0.817902 8.63989 0.605684 7.57299C0.393465 6.5061 0.502383 5.40023 0.918665 4.39524C1.33495 3.39025 2.0399 2.53126 2.94437 1.92692Z"
                          fill="#0E7BD6"
                          stroke="#0E7BD6"
                        />
                      </svg>
                    </span>
                    <ng-template #titleTemplate> </ng-template>
                    <ng-template #contentTemplate>
                      <span nz-icon nzType="check"></span>
                      Content
                    </ng-template>
                  </nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    nzErrorTip="Please choose your country"
                  >
                    <nz-select
                      required
                      formControlName="country"
                      nzPlaceHolder="Choose Country"
                    >
                      <nz-option
                        nzValue="US"
                        nzLabel="United States"
                      ></nz-option>
                      <nz-option nzValue="DE" nzLabel="Germany"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Password</nz-form-label>
                  <nz-form-control
                    [nzSpan]="24"
                    [nzErrorTip]="passwordErrorTemplate"
                  >
                    <nz-input-group [nzSuffix]="suffixTemplate">
                      <input
                        formControlName="password"
                        [type]="passwordVisible ? 'text' : 'password'"
                        nz-input
                        placeholder="Password"
                      />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <span
                        nz-icon
                        [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                        (click)="passwordVisible = !passwordVisible"
                      ></span>
                    </ng-template>
                    <ng-template #passwordErrorTemplate>
                      <!-- Different error messages based on the validation errors -->
                      <ng-container
                        *ngIf="
                          validateForm.controls.password.errors?.['required']
                        "
                        >Please enter your password.</ng-container
                      >
                      <ng-container
                        *ngIf="
                          validateForm.controls.password.errors?.['minlength']
                        "
                        >Your password should be min 8 characters.</ng-container
                      >
                      <ng-container
                        *ngIf="
                          validateForm.controls.password.errors?.[
                            'strongPassword'
                          ]
                        "
                      >
                        Password must contain at least 8 characters, including
                        an uppercase letter, a lowercase letter, a number, and a
                        special character (e.g., !&#64;#$%^&*()-).
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <nz-divider nzText="or"></nz-divider>

            <nz-form-item>
              <app-google-signin
                (buttonClick)="onGoogleClick()"
                (token)="onToken($event)"
              ></app-google-signin>
            </nz-form-item>

            <nz-form-item class="eula-item">
              <nz-form-control
                nzErrorTip="You must accept the Terms of Service and Privacy Policy to create an account."
              >
                <label
                  >By signing up, you are creating a Shopzo account, and you
                  agree the
                  <a href="/terms-of-use" (click)="onTermsClick()"
                    >Terms of Use</a
                  >
                  and <a href="/privacy-policy">Privacy Policy</a>.</label
                >
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-row>
            <button
              type="submit"
              class="submit"
              nz-button
              nzType="primary"
              [nzLoading]="appService.isLoading$ | async"
            >
              Create account
            </button>
          </div>
        </form>
      </div>
    </div>
    <div nz-col [nzSpan]="0" [nzLg]="12">
      <img src="/assets/images/illustration-1.svg" alt="" />
    </div>
  </div>
</div>
