import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { combineLatest } from 'rxjs';
import { FacetValue, LanguageCode } from '../../gql/shop/generated';
import { AppService } from '../../service/app.service';
import { FacetService } from '../../service/facet.service';

@Component({
  selector: 'app-facet-detail',
  templateUrl: './facet-detail.component.html',
  styleUrl: './facet-detail.component.less',
})
export class FacetDetailComponent implements OnInit, OnDestroy {
  isCreation = true;
  id: string | null = null;

  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>;
    name: FormControl<string>;
    code: FormControl<string>;
    visibility: FormControl<boolean>;
    id: FormControl<string>;
    facetValues: FormArray;
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    id: [''],
    visibility: [false],
    name: ['', [Validators.required, Validators.minLength(3)]],
    code: ['', [Validators.required, Validators.minLength(3)]],
    facetValues: this.formBuilder.array([]),
  });

  get facetValues() {
    return this.validateForm.get('facetValues') as FormArray;
  }

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private facetService: FacetService,
  ) {}

  ngOnInit() {
    this.setupListeners();
  }

  ngOnDestroy() {
    this.facetService.clearFacet();
  }

  setupListeners() {
    // Setup Parameter Listener
    combineLatest([this.route.paramMap, this.route.data]).subscribe(
      ([params, data]) => {
        this.id = params.get('id');
        this.isCreation = data['isCreation'];
        console.log(this.isCreation);

        if (!this.isCreation && this.id) {
          this.facetService.fetchFacet(this.id).subscribe();
        }
      },
    );

    // Setup Facet Listener
    this.facetService.facet$.subscribe((facet) => {
      if (!facet) {
        return;
      }

      const facetValuesControl = this.validateForm.get(
        'facetValues',
      ) as FormArray;
      facetValuesControl.clear();
      const sortedItems = [...facet.valueList.items].sort(
        (a: FacetValue, b: FacetValue) => {
          return +new Date(a.createdAt) - +new Date(b.createdAt);
        },
      );

      const facetValues = sortedItems.map((item: FacetValue) => {
        // Add control for each item we fetch
        const newGroup = this.formBuilder.group({
          name: ['', Validators.required],
          code: ['', Validators.required],
          id: [''],
        });
        facetValuesControl.push(newGroup);

        return {
          name: item.name,
          code: item.code,
          id: item.id,
        };
      });

      // Update the value of form
      this.validateForm.patchValue({
        name: facet?.name,
        code: facet?.code,
        visibility: !facet?.isPrivate,
        facetValues,
      });
    });
  }

  submitForm() {
    this.appService.showProgress();
    this.validateForm.markAllAsTouched();
    this.validateForm.validate();
    const formRaw = this.validateForm.getRawValue();

    if (this.validateForm.valid) {
      if (this.isCreation) {
        this.facetService
          .createFacet({
            code: formRaw.code,
            isPrivate: !formRaw.visibility,
            translations: [
              {
                languageCode: LanguageCode.en,
                name: formRaw.name ?? '',
              },
            ],
            values: formRaw.facetValues.map((value) => {
              return {
                translations: [
                  {
                    languageCode: LanguageCode.en,
                    name: value.name,
                  },
                ],
                code: value.code,
              };
            }),
          })
          .subscribe((response) => {
            const id = response.data?.createFacet?.id;
            this.router.navigate([`/admin/facets/${id}`]);
          });
      } else {
        this.facetService
          .updateFacet({
            id: this.id as string,
            code: formRaw.code,
            isPrivate: !formRaw.visibility,
            translations: [
              {
                languageCode: LanguageCode.en,
                name: formRaw.name ?? '',
              },
            ],
          })
          .subscribe();

        const exists = formRaw.facetValues.filter(
          (item: FacetValue) => item.id,
        );
        const news = formRaw.facetValues.filter((item: FacetValue) => !item.id);

        if (exists.length > 0) {
          this.facetService
            .updateFacetValues(
              exists.map((value) => {
                return {
                  id: value.id,
                  translations: [
                    {
                      languageCode: LanguageCode.en,
                      name: value.name,
                    },
                  ],
                  code: value.code,
                } as FacetValue;
              }),
            )
            .subscribe();
        }

        if (news.length > 0) {
          this.facetService
            .createFacetValues(
              news.map((value: FacetValue) => {
                return {
                  id: value.id,
                  facetId: this.id,
                  translations: [
                    {
                      languageCode: LanguageCode.en,
                      name: value.name,
                    },
                  ],
                  code: value.code,
                } as FacetValue;
              }),
            )
            .subscribe();
        }
      }
    } else {
      console.error('Form is not valid', this.validateForm);
    }
  }

  addNewFacetValue() {
    const facetValues = this.validateForm.get('facetValues') as FormArray;
    const newGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      code: ['', [Validators.required, Validators.minLength(3)]],
    });

    facetValues.push(newGroup);
  }

  removeFacetValue(index: number): void {
    const values = this.validateForm.get('facetValues') as FormArray;
    if (index >= 0 && index < values.length) {
      values.removeAt(index);
    }
  }

  updateFacetValue(index: number, name: string, code: string): void {
    const values = this.validateForm.get('facetValues') as FormArray;
    if (index >= 0 && index < values.length) {
      const item = values.at(index);
      item.patchValue({
        name: name,
        code: code,
      });
    }
  }

  getFacetValueControl(index: number, key: string) {
    const values = this.validateForm.get('facetValues') as FormArray;
    const formGroup = values.at(index) as FormGroup;

    return formGroup.controls[key] as FormControl;
  }

  getControl(key: string) {
    return this.validateForm.get(key) as FormControl;
  }
}
